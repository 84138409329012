import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useDeleteBill, useReadBills } from '../../hooks/bill';
import { useReadCompanies } from '../../hooks/company';
import { useFilterHireChart, useUpdateHireChart } from '../../hooks/hirechart';

const SearchBy = [
  {
    label: 'Sub Agent',
    value: 'subagent',
  },
  {
    label: 'Client Name',
    value: 'client',
  },
  {
    label: 'Owner Code',
    value: 'owncode',
  },
  {
    label: 'Veh. No.',
    value: 'vno',
  },
];

const PendingBills = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: bills, isLoading } = useReadBills();

  // eslint-disable-next-line
  // const { data: hirecharts, refetch } = useReadHireCharts();
  const { mutate: filterHireChartMutate, isPending: isPendingHires } =
    useFilterHireChart();

  const { mutate: useUpdateHireChartMutate } = useUpdateHireChart();

  const [selectedBill, setSelectedBill] = useState<any>(null);

  const [selectedHirecharts, setSelectedHirecharts] = useState<any>([]);

  const { mutate: useDeleteBillMutate } = useDeleteBill();

  const [modifiedHirecharts, setModifiedHirecharts] = useState([]);
  const [srlocalhirechart, setSRLocalHirechart] = useState<any[]>([]);

  // modifiedHirecharts = hirecharts?.filter(
  //   h => Number(h.bill) === 1 && (h.billno === '0' || h.billno === '')
  // );

  // modifiedHirecharts = modifiedHirecharts?.map((h: any) => {
  //   const bill: any = bills?.find(b => String(b.hid) === String(h.id));
  //   // h.billed = bill?.billed;
  //   // h.total = bill?.total;
  //   return {
  //     id: h.id,
  //     hirechart: h,
  //     bill,
  //   };
  // });
  // modifiedHirecharts = modifiedHirecharts?.filter(
  //   (h: any) => Number(h?.bill?.billed) === 0
  // );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { data: companiesData } = useReadCompanies();
  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    document.title = 'Bill - nunes';
    handleSearch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modifiedHirecharts) {
      const hirechartsWithSr = modifiedHirecharts.map(
        (hireItems: any, index: number) => ({
          ...hireItems,
          sr: index + 1,
        })
      );
      setSRLocalHirechart(hirechartsWithSr);
    }
  }, [modifiedHirecharts]);

  const navigate = useNavigate();

  useEffect(() => {
    if (companiesData && companiesData.length) {
      setCompanies(companiesData);
      // setCompany('any');
    }
  }, [companiesData]);

  const handleDetails = (rowData: any) => {
    if (rowData) {
      setSelectedBill(rowData);
      navigate(`/pendingBills/${rowData.id}`);
    }
  };

  const setSelectedRows = (rowsData: any) => {
    setSelectedHirecharts(rowsData);
  };

  const handleMakeBill = () => {
    let hirechartToDisplay: any = [];
    hirechartToDisplay = selectedHirecharts.map((h: any) =>
      modifiedHirecharts?.find((hc: any) => hc.id === h.hirechart.id)
    );
    localStorage.setItem('hirecharts', JSON.stringify(hirechartToDisplay));
    navigate(`/bill/view`);
  };

  const handleEdit = (rowData: any) => {
    setSelectedBill(rowData);
    navigate(`/pendingBills/${rowData.id}`);
  };

  const handleDelete = (rowData: any) => {
    setSelectedBill(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedBill) {
      // eslint-disable-next-line
      useDeleteBillMutate(
        { id: +selectedBill.bill.id },
        {
          onSuccess: () => {
            // eslint-disable-next-line
            useUpdateHireChartMutate(
              {
                id: +selectedBill.id,
                billno: '0',
                bill: 0,
              },
              {
                onSuccess: () => {
                  enqueueSnackbar('Content Deleted', { variant: 'success' });
                  handleSearch();
                },
              }
            );
          },
          onError: () => {},
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      startDate: (() => {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          2
        );
        return firstDayOfMonth.toISOString().split('T')[0];
      })(),
      endDate: (() => {
        const today = new Date();
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1
        );
        return lastDayOfMonth.toISOString().split('T')[0];
      })(),
      company: '',
      hirechartType: [],
      searchBy: '',
      searchValue: '',
    },
  });

  const handleSearch = handleSubmit((formData: any) => {
    const filterCriteria = {
      ...(formData?.startDate && { startDate: formData?.startDate }),
      ...(formData?.endDate && { endDate: formData?.endDate }),
      ...(formData?.company && { company: formData?.company }),
    };
    if (formData?.hirechartType && formData.hirechartType.length > 0) {
      filterCriteria.hirechartType = formData.hirechartType;
    }

    if (formData.searchBy && formData.searchValue) {
      filterCriteria[formData.searchBy] = formData.searchValue ?? '';
    }

    filterHireChartMutate(filterCriteria, {
      onSuccess: (filteredData: any) => {
        if (filteredData?.data) {
          let processedData = filteredData?.data?.filter(
            (h: any) =>
              Number(h.bill) === 1 && (h.billno === '0' || h.billno === '')
          );

          processedData = processedData?.map((h: any) => {
            const bill: any = bills?.find(b => String(b.hid) === String(h.id));
            // h.billed = bill?.billed;
            // h.total = bill?.total;
            return {
              id: h.id,
              hirechart: h,
              bill,
            };
          });
          processedData = processedData?.filter(
            (h: any) => Number(h?.bill?.billed) === 0
          );
          setModifiedHirecharts(processedData);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to fetch filtered data', { variant: 'error' });
      },
    });
  });

  const columns: any = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Bill No',
      field: 'billno',
      render: (rowData: any) => `${rowData?.hirechart?.billno} `,
    },
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: (rowData: any) => `${formatDate(rowData?.hirechart?.date)}`,
    },
    {
      title: 'Company',
      field: 'company',
      render: (rowData: any) => `${rowData?.hirechart?.company} `,
    },
    {
      title: 'Vehicle Type',
      field: 'vtype',
      render: (rowData: any) => `${rowData?.hirechart?.vtype} `,
    },
    {
      title: 'Vehicle No',
      field: 'vno',
      render: (rowData: any) => `${rowData?.hirechart?.vno} `,
    },
    {
      title: 'Particulars',
      render: (rowData: any) =>
        `${rowData.hirechart?.particulars} ${rowData.hirechart?.particulars_type}`,
    },
    {
      title: 'Sub agent',
      field: 'subagent',
      render: (rowData: any) => `${rowData?.hirechart?.subagent} `,
    },
    {
      title: 'Client',
      field: 'client',
      render: (rowData: any) => `${rowData?.hirechart?.client} `,
    },
    {
      title: 'Total',
      field: 'total',
      render: (rowData: any) => `${rowData?.bill?.total || 0} `,
    },
    {
      title: 'billId',
      field: 'billId',
      hidden: true,
      render: (rowData: any) => `${rowData?.bill?.id} `,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Pending Bills" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Accordion style={{ padding: '10px' }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: 'primary.main',
                    '&.Mui-expanded': { transform: 'rotate(180deg)' },
                  }}
                />
              }
              aria-controls="filter-content"
              id="filter-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
              }}
            >
              <Typography variant="h6">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="From Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="To Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ width: '100%' }}
                    >
                      <InputLabel id="company-select-label">
                        Select Company
                      </InputLabel>
                      <Controller
                        control={control}
                        name="company"
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="company-select-label"
                            label="Select Company"
                          >
                            <MenuItem value="">
                              <em>Select Company</em>
                            </MenuItem>
                            {companies?.map((comp: any) => (
                              <MenuItem key={comp?.id} value={comp?.name}>
                                {comp?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="searchBy">Search By</InputLabel>
                      <Controller
                        control={control}
                        name="searchBy"
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Search By"
                            inputProps={{
                              id: 'searchBy',
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Search</em>
                            </MenuItem>
                            {SearchBy?.map((comp: any) => (
                              <MenuItem key={comp?.value} value={comp?.value}>
                                {comp?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="searchValue"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Search Value"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: false }}
                          type="text"
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    {['DEP', 'ARR', 'D/A', 'S/S', 'S/D', 'A/S', 'DISP'].map(
                      (option: any, index: number) => (
                        <Grid key={index}>
                          <FormControlLabel
                            control={
                              <Controller
                                name="hirechartType"
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    onChange={e =>
                                      field.onChange(
                                        e.target.checked
                                          ? [...field.value, option]
                                          : field.value.filter(
                                              item => item !== option
                                            )
                                      )
                                    }
                                    color="primary"
                                    value={option}
                                  />
                                )}
                              />
                            }
                            label={option}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>

          <Grid
            item
            xs={4}
            sm={2}
            alignSelf="flex-start"
            paddingTop={4}
            paddingLeft={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleMakeBill}
            >
              Make Bill
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={srlocalhirechart || []}
              isLoading={isLoading && !isPendingHires}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
                selection: true,
                actionsColumnIndex: -1,
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              onSelectionChange={rows => setSelectedRows(rows)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Bill',
                  position: 'row',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Bill',
                  position: 'row',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default PendingBills;
